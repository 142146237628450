import request from '@/utils/request'
import ENDPOINT from '@/config/endpoint'

export function getWorkingLocations(params) {
  return request({
    url: ENDPOINT.WORKING_LOCATION,
    method: 'get',
    params
  })
}

export function createWorkingLocation(data) {
  return request({
    url: ENDPOINT.WORKING_LOCATION,
    method: 'post',
    data
  })
}

export function updateWorkingLocation(data) {
  return request({
    url: ENDPOINT.WORKING_LOCATION,
    method: 'patch',
    data
  })
}

export function deleteWorkingLocation(params) {
  return request({
    url: ENDPOINT.WORKING_LOCATION,
    method: 'delete',
    params
  })
}
