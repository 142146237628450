<template>
  <el-table v-loading="loading" :data="listUser">
    <el-table-column label="STT" type="index" width="50"></el-table-column>
    <el-table-column width="60">
      <template slot-scope="scope">
        <el-avatar size="large" :src="scope.row.avatar_url"></el-avatar>
      </template>
    </el-table-column>
    <el-table-column label="Tên" prop="name" min-width="200">
      <template slot-scope="scope">
        <a href="javascript:;" @click="handleUpdateUser(scope.row)">{{ scope.row.name }}</a>
      </template>
    </el-table-column>
    <el-table-column label="Email" prop="email" min-width="300"></el-table-column>
    <el-table-column label="Phone" prop="phone" width="150"></el-table-column>
    <el-table-column v-if="staffType === 'moderator'" label="Tổ chức quản lý" prop="organizations" width="400">
      <template slot-scope="scope">
        <router-link v-if="scope.row.organizations.length > 0" :to="{ name: 'OrganizationsInfo', params: { id: scope.row.organizations[0].id }}">
          {{ scope.row.organizations[0] | getOrgName }}
        </router-link>
      </template>
    </el-table-column>
    <el-table-column label="Ngày tạo" prop="created_at" width="100"></el-table-column>
    <el-table-column label="Thao tác" width="100" align="center" fixed="right">
      <template slot-scope="scope">
        <el-tooltip content="Chỉnh sửa thông tin" placement="top">
          <el-button
            icon="el-icon-edit"
            type="primary"
            circle
            size="small"
            @click="handleUpdateUser(scope.row)"
          ></el-button>
        </el-tooltip>
        <el-tooltip content="Xóa tài khoản" placement="top">
          <el-button
            icon="el-icon-delete"
            circle
            type="danger"
            size="small"
            @click="handleDeleteUser(scope.row.id)"
          ></el-button>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { deleteUser } from '@/services/user'
export default {
  name: 'ListUserStaff',
  props: {
    listUser: Array,
    loading: Boolean,
    staffType: String
  },
  data() {
    return {
      data: []
    }
  },
  methods: {
    handleDeleteUser(id) {
      this.$confirm('Bạn có chắc chắn muốn xóa tài khoản này?', 'Cảnh báo', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Hủy',
        type: 'warning'
      }).then(async () => {
        await deleteUser(id).then(() => {
          this.$notify.success({
            title: 'Thành công',
            message: 'Xóa tài khoản thành công'
          })
          this.$emit('reload')
        }).catch(() => {
          this.$notify.error({
            title: 'Thất bại',
            message: 'Xóa tài khoản thất bại'
          })
        })
      }).catch(() => {})
    },

    handleUpdateUser(row) {
      this.$emit('update', row)
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  color: #1174d4;
}
</style>
